<div class="slider-container">
  <div class="blocked-section" [ngStyle]="{'min-width':this.fixedAreaMinWidthStr,'width':this.fixedAreaWidthStr}">
    <ng-content select="[blocked]">

    </ng-content>
  </div>
  <div class="sliding-section" [ngStyle]="{'width':sliderAreaWidthStr}"  [id]="id">
    <div class="content" [ngStyle]="{'width':sliderAreaWidthStr}" >
    
        <ng-content select="[slider]" >

        </ng-content>

    </div>
  </div>
</div>