import { NgModule, inject } from '@angular/core';
import { CanMatchFn, PreloadAllModules, Route, Router, RouterModule, Routes, UrlSegment } from '@angular/router';
import { AuthService } from './services/auth.service';

const canMatch: CanMatchFn = async (route: Route, segments: UrlSegment[]) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const loggedUser = authService.loggedUser$.getValue();

  if (!!loggedUser === false) {
    await router.navigate(['/auth', 'login']);
    return false;
  }

  // Check for admin
  // TODO...

  return true;
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'auth/register/:code',
    loadChildren: () => import('./pages/auth-register/auth-register.module').then( m => m.AuthRegisterPageModule)
  },
  {
    path: 'auth/register',
    loadChildren: () => import('./pages/auth-register/auth-register.module').then( m => m.AuthRegisterPageModule)
  },
  {
    path: 'auth/verify/:user',
    loadChildren: () => import('./pages/auth-validate/auth-validate.module').then( m => m.AuthValidatePageModule)
  },
  {
    path: 'auth/login',
    loadChildren: () => import('./pages/auth-login/auth-login.module').then( m => m.AuthLoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canMatch: [canMatch],
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule),
    canMatch: [canMatch],
  },
  {
    path: 'auth/profile',
    loadChildren: () => import('./pages/auth-profile/auth-profile.module').then( m => m.AuthProfilePageModule)
  },
  {
    path: 'network',
    //redirectTo:'workinprogress'
    loadChildren: () => import('./pages/network/network.module').then( m => m.NetworkPageModule)
  },
  {
    path: 'walletpool',
    loadChildren: () => import('./pages/walletpool/walletpool.module').then( m => m.WalletpoolPageModule)
  },
  {
    path: 'pull',
    loadChildren: () => import('./pages/pull/pull.module').then( m => m.PullPageModule)
  },
  {
    path: 'pull/:id',
    loadChildren: () => import('./pages/pull/pull.module').then( m => m.PullPageModule)
  },
  {
    path: 'pulls',
    loadChildren: () => import('./pages/pulls/pulls.module').then( m => m.PullsPageModule)
  },
  {
    path: 'historypool',
    loadChildren: () => import('./pages/historypool/historypool.module').then( m => m.HistorypoolPageModule)
  },
  {
    path: 'historypool/:id',
    loadChildren: () => import('./pages/historypool/historypool.module').then( m => m.HistorypoolPageModule)
  },
  {
    path: 'dev',
    loadChildren: () => import('./pages/dev/dev.module').then( m => m.DevPageModule)
  },
  {
    path: 'community',
    loadChildren: () => import('./pages/community/community.module').then( m => m.CommunityPageModule)
  },
  {
    path: 'historyuserpool/:id/:typepool',
    loadChildren: () => import('./pages/historyuserpool/historyuserpool.module').then( m => m.HistoryuserpoolPageModule)
  },
  {
    path: 'transactions/:id',
    loadChildren: () => import('./pages/transactions/transactions.module').then( m => m.TransactionsPageModule)
  },
  {
    path: 'workinprogress',
    loadChildren: () => import('./pages/workinprogress/workinprogress.module').then( m => m.WorkinprogressPageModule)
  },
  {
    path: 'businessplan-network',
    redirectTo:'workinprogress'
    //loadChildren: () => import('./pages/calculator/network/network.module').then( m => m.NetworkPageModule)
  },
  {
    path: 'businessplan-pool',
    redirectTo:'workinprogress'
    //loadChildren: () => import('./pages/calculator/pool/pool.module').then( m => m.PoolPageModule)
  },
  {
    path: 'network',
    loadChildren: () => import('./pages/calculator/network/network.module').then( m => m.NetworkPageModule)
  },
  {
    path: 'pool',
    loadChildren: () => import('./pages/calculator/pool/pool.module').then( m => m.PoolPageModule)
  },
  {
    path: 'media',
    loadChildren: () => import('./pages/media/media.module').then( m => m.MediaPageModule)
  },
  {
    path: 'mining',
    //redirectTo:'workinprogress'
    loadChildren: () => import('./pages/mining/mining.module').then( m => m.MiningPageModule)
  },  {
    path: 'network-gpm',
    loadChildren: () => import('./pages/network-gpm/network-gpm.module').then( m => m.NetworkGpmPageModule)
  },
  {
    path: 'ambassador',
    loadChildren: () => import('./pages/ambassador/ambassador.module').then( m => m.AmbassadorPageModule)
  }




  /*
  {
    path: 'test',
    loadChildren: () => import('./pages/test/test.module').then( m => m.TestPageModule)
  }*/



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
