import { Component, OnDestroy, OnInit } from '@angular/core';
import User from 'src/app/models/user';
import WalletUser from 'src/app/models/userwallet.model';
import { AuthService } from 'src/app/services/auth.service';
import { Coin, DatasessionService } from 'src/app/services/datasession.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit, OnDestroy {
  id:string = "";
  coinsId:string[] = [];
  get coins():Coin[]{
    return this.dataSession.getCoinsByIds(this.coinsId) as Coin[];
  };
  //wallets:Wallet[] = [];

  isAdmin:boolean=false;
  loggedUser:User|null = null;
  walletUser:WalletUser = new WalletUser();
  gptToDollar:number=0;
  kasToDollar:number = 0;
  loggedUserIsReady:boolean = false;
  gptToDollarIsReady:boolean = false;
  kasToDollarIsReady:boolean = false;
  componentIsReady:boolean = false;
  constructor(
    public authService:AuthService,
    public dataSession:DatasessionService,
    public userService:UsersService) { 
    }
  ngOnDestroy(): void {
    //to override
  }
  
  async ngOnInit() {   
    await this.onComponentIsActive();
  }
  onComponentIsActive(){
    this.dataSession.gptToDollar$.subscribe((value)=>{    
      this.gptToDollarIsReady = true;  
      this._onGoldVaueChange(value);
      this._checkComponentIsActive();
    });
    this.dataSession.kasToDollar$.subscribe((value)=>{    
      this.kasToDollarIsReady = true;  
      this._onKasVaueChange(value);
      this._checkComponentIsActive();
    });
    this.dataSession.loggedUser$.subscribe(
      (resp)=>{
        this.loggedUser = resp as User;
        this.isAdmin = resp?.role=="admin";
        this.loggedUserIsReady = true;
        if(resp!=null){
          this._onUserChange();     
          this._onWalletChange(resp);
          this._checkComponentIsActive();
        }
        //this.onComponentIsReady();
       }
    );

  }
  _checkComponentIsActive(){
    //chiama onComponentIsReady solo una volta
    let test =  this.gptToDollarIsReady && this.loggedUserIsReady && this.kasToDollarIsReady
    
    if(!this.componentIsReady && test)
      {
        this.componentIsReady = test;
        this.onComponentIsReady();
      }  
  }
  onComponentIsReady(){
    //to override
  }
  private _onUserChange() {
    this.afterUserChange();    
  }
  afterUserChange(){
    //console.log(this.loggedUser);
    //to override
  }
  private _onWalletChange(user:User){
    this.walletUser.gptReward = user.wGPTR as number;
    this.walletUser.gptAvailable = user.wGPT as number;
    this.walletUser.eur = user.wEUR as number;
    this.walletUser.usdt = user.wUSDT as number;
    this.walletUser.gptO = user.wGPTO as number;
    this.walletUser.btc = user.wBTC as number;
    this.walletUser.wMinUSDT = user.wMinUSDT as number;
    this.walletUser.bgpm = user.wBGPM as number;
    this.walletUser.kgpm = user.wKGPM as number;
    
    this.walletUser.totalKasR = user.totalKasR as number;
    this.walletUser.wKasPersonalReward = user.wKASMyR as number;
    this.walletUser.wKasSpot = user.wKAS as number;
    this.walletUser.dailyK = user.dailyKAS as number;
    this.walletUser.monthlyK = user.monthlyKasR as number;
    this.walletUser.totalCommunityKAS = user.totalCommunityKAS as number;
    this.walletUser.wKasMining = user.wKASR as number;
    
    
    this.afterWalletChange();
  }
  afterWalletChange(){
    //console.log(this.walletUser);
    //to override
  }
  private _onGoldVaueChange(value:any){
    //console.log("GPT value: ", value + "$");
    this.gptToDollar = value;
    this.aftergptToDollarChange();
  }
  private _onKasVaueChange(value:any){
    //console.log("Kas value: ", value + "$");
    this.kasToDollar = value;
    this.afterKasToDollarChange();
  }
  aftergptToDollarChange(){
    //to override
  }
  afterKasToDollarChange(){
    //to override

  }
  round(number:number, decimals:number){
    let factor = 10 ** decimals ; // ** -> operatore per elevamento a potenza
    let rounded =Math.floor(number * factor) / factor;
    return rounded;
  }
}
