import { AmbassadorPage } from './pages/ambassador/ambassador.page';
import { DatasessionService } from 'src/app/services/datasession.service';
import { Component, HostListener, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UsersService } from './services/users.service';
import { AuthService } from './services/auth.service';
import { CommonService } from './commons/commons.service';
import { ModalController } from '@ionic/angular';
import { SwapItemComponent } from './components/swap-item/swap-item.component';
import { WalletItemComponent } from './components/wallet-item/wallet-item.component';
import { TransferItemComponent } from './components/transfer-item/transfer-item.component';
import { environment } from 'src/environments/environment';
import User from './models/user';
import { MiningPurchaseModalComponent } from './components/mining-purchase-modal/mining-purchase-modal.component';
import  packageInfo  from '../../package.json';

const HIDDEN_MENU_URLS: Array<string> = [
  '/auth/login',
  '/auth/register',
  '/auth/verify'
];

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  version:string ="";
  isMenuVisible: boolean = false;
  public testing: boolean=false;
  get admin(): boolean{
    return this.loggedUser?.role=='admin';
  };
  get isNotLogin():boolean{    
    return this.router.url.indexOf('login')==-1 && this.router.url.indexOf('register')==-1;
  }
_menuItems:any[] = [];
 get menuItems():any{
    return this._menuItems;
 }
 withMenuBackground:boolean = true;
  loggedUser:any;
  constructor(
    private readonly router: Router,
    private commonService:CommonService,
    private authService: AuthService,
    private ngZone:NgZone,
    private modalController:ModalController,
    private dataSessionService:DatasessionService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setMenuVisibility(event.url);
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    let t = setTimeout(()=>{
      this.withMenuBackground = window.screen.width < 1200;
      this.ngZone.run(()=>{

      });
    },80);

  }
  
  async ngOnInit(){
    this.version = packageInfo.version;
    this.withMenuBackground = window.screen.width < 1200;
     this.authService.loggedUser$.subscribe(lu=>{
      //this.menuItems = [];
      this.loggedUser = lu;
      this._menuItems =[
        {
          label: 'Dashboard',
          // icon: 'pi pi-th-large',
          css: 'dashboard-button',
          icon: 'grid-outline', // <ion-icon name="grid-outline"></ion-icon>
          routerLink: ['/dashboard'],
          enabled: true,
          hasChildren: false,
          click:this.routeTo.bind(this)
        },
        {
          label: 'Wallet',
          // icon: 'pi pi-wallet',
          // icon: 'wallet-outline', // <ion-icon name="wallet-outline"></ion-icon>
          css: 'wallet-button',
          subMenuOpened: false,
          hasChildren: true,
          enabled: true,
          click:()=>{},
          items: [
            {
              label: 'WALLET SPOT',
              routerLink: ['/wallet'],
              click:this.routeTo.bind(this)
            },
            {
              label: 'WALLET REWARD',
              click:this.openWalletReward.bind(this)
            },
      
            {
              label: 'WALLET POOL',
              // icon: 'pi pi-circle-fill',
              icon: 'ellipse', // <ion-icon name="ellipse"></ion-icon>
              routerLink: [environment.production?'/workinprogress':'/walletpool'],
              click:this.routeTo.bind(this)
            },
            {
              label: 'WALLET MINING',
              routerLink: ['/mining'],
              click:this.openPurchase.bind(this)
            },
      
      
          ]
        },
        {
          label: 'Mining',
          // icon: 'pi pi-chart-pie',
          hasChildren: false,
          css: 'mining-button',
          enabled: true,
          routerLink: ['/mining'],
          click:this.routeTo.bind(this)
        },
        {
          label: 'Pool',
          // icon: 'pi pi-chart-pie',
          hasChildren: false,
          css: 'pool-button',
          enabled: true,
          icon: 'share-social-outline', // <ion-icon name="pie-chart-outline"></ion-icon>
          routerLink: ['/network'],
          click:this.routeTo.bind(this)
        },
        {
          label: 'Community',
          css: 'community-button',
          // icon: 'pi pi-share-alt',
          enabled: true,
          icon: 'share-social-outline', // <ion-icon name="share-social-outline"></ion-icon>
          hasChildren: true,
          click:()=>{},
          items: [
            {
              label: 'GPR',
              routerLink: ['/community'],
              click:this.routeTo.bind(this)
            },
            {
              label: 'GPM',
              routerLink: ['/network-gpm'],
              click:this.routeTo.bind(this)
            }
      
          ]
        },
        {
          label: 'Swap',
          hasChildren: false,
          css: 'swap-button',
          // icon: 'pi pi-share-alt',
          enabled: true,
          icon: 'share-social-outline', // <ion-icon name="share-social-outline"></ion-icon>
          routerLink: ['/'],
          click:this.openSwap.bind(this)
        },
        {
          label: 'Media',
          hasChildren: false,
          css: 'media-button',
          // icon: 'pi pi-share-alt',
          enabled: true,
          icon: 'share-social-outline', // <ion-icon name="share-social-outline"></ion-icon>
          routerLink: ['/media'],
          click:this.routeTo.bind(this)
        },
        {
          label: 'Ambassador',
          hasChildren: false,
          css: 'ambassador-button',
          // icon: 'pi pi-share-alt',
          enabled: this.admin || this.loggedUser?.ambassador,
          icon: 'medal-outline', // <ion-icon name="share-social-outline"></ion-icon>
          routerLink: ['/ambassador'],
          click:this.routeTo.bind(this)
        },
      
        // {
        //   label: 'White paper',
        //   // icon: 'pi pi-file',
        //   icon: 'document-outline', // <ion-icon name="document-outline"></ion-icon>
        //   routerLink: ['/'],
        // },
        {
          label: 'Simulator',
          // icon: 'pi pi-wallet',
          // icon: 'wallet-outline', // <ion-icon name="wallet-outline"></ion-icon>
          css: 'calculator-button',
          subMenuOpened: false,
          hasChildren: true,
          enabled: true,
          click:()=>{},
          items: [
            {
              label: 'Network',
              routerLink: ['/businessplan-network'],
              click:this.routeTo.bind(this)
            },
            {
              label: 'Pool',
              routerLink: ['/businessplan-pool'],
              click:this.routeTo.bind(this)
            }
      
          ]
        },
        {
          label: 'Settings',
          // icon: 'pi pi-cog',
          hasChildren: false,
          css: 'setting-button',
          icon: 'cog-outline', // <ion-icon name="cog-outline"></ion-icon>
          enabled: this.admin,
          routerLink: ['/pulls'],
          click:this.routeTo.bind(this)
        },
        {
          label: 'ref',
          // icon: 'pi pi-cog',
          hasChildren: false,
          css: 'reflink',
          enabled: true,
          icon: 'cog-outline', // <ion-icon name="cog-outline"></ion-icon>
          routerLink: ['/'],
          click:this.routeTo.bind(this)
        }
      ];
      
      //if(lu!=null)
        //this.menuItems = this._menuItems;
    })  
  }
  onActivate(component:any){
    if(component.onComponentIsActive)
       component.onComponentIsActive();
  }
  async openMyPurchaseModal(){
      const modal = await this.modalController.create({
        component: MiningPurchaseModalComponent,
        id:'MiningPurchaseModalComponent',
        componentProps: {
       
        },
        cssClass: 'modal-max-width-375'
      });
      modal.present();
  
      const { data, role } = await modal.onWillDismiss();
  
      if (role === 'confirm') {
    
      }
    
  }
  async openPurchase(){
    const modal = await this.modalController.create({
      id:"MiningPurchaseModalComponent",
      component: MiningPurchaseModalComponent,
      componentProps: {
        //valuta: this.valuta
      },
      cssClass: 'modal-max-width-375'
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
          //

    }   
  }
  async openSwap(){
    const modal = await this.modalController.create({
      id:"modalSwap",
      component: SwapItemComponent,
      componentProps: {
        //valuta: this.valuta
        coinsIdsFrom:["GPT","USDT"],
        coinsIdsTo:["GPT","USDT"]
      },
      cssClass: 'modal-max-width-375'
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
          //

    }
  }
  async openWalletReward(){
    const modal = await this.modalController.create({
      id:"modalSwap",
      component: TransferItemComponent,
      componentProps: {
        //valuta: this.valuta
        coinId:'GPT',
        fromWallets:this.dataSessionService.getWalletByIds(['reward']),
        toWallets:this.dataSessionService.getWalletByIds(['spot']),        
        maxAvailable:this.dataSessionService.walletUser.gptReward
      },
      cssClass: 'modal-max-width-375'
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
          //

    }
  }

  setMenuVisibility(currentUrl: string) {
    const index: number = HIDDEN_MENU_URLS.findIndex((url: string) => currentUrl.startsWith(url));
    this.isMenuVisible = (index === -1);
  }
  routeTo(url:any[]|undefined){
    this.router.navigate(url!);
  }
  goItem(item: any ){
    if (item.label=="ref"){
      //this.loggedUser = this.authService.loggedUser$.getValue();
      this.copyTextToClipboard(`${environment.appUrl}auth/register/${this.loggedUser.referralCode}`);
    }
  }

  copyTextToClipboard(text: string) {
    // Verifica se il browser supporta l'API Clipboard
    if (!navigator.clipboard) {
        console.error("Clipboard API non supportata");
        return;
    }

    // Copia il testo negli appunti del sistema
    navigator.clipboard.writeText(text)
        .then(() => {
            this.commonService.showToast("clipboardToast","Copied",4000,"bottom","success")
        })
        .catch((err) => {
            console.error("Errore durante la copia del testo negli appunti:", err);
        });
  }

}
