import { AfterViewChecked, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IonSelect, ModalController, ToastController } from '@ionic/angular';
import { CommonService } from 'src/app/commons/commons.service';
import { AuthService } from 'src/app/services/auth.service';
import { Coin, DatasessionService } from 'src/app/services/datasession.service';
import { GPTService } from 'src/app/services/gpt.service.';
import { SwapInput } from 'src/app/services/models/swap';
import { WalletService } from 'src/app/services/wallet.service';
import { BaseComponent } from 'src/app/commons/base/base.component';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-swap-item',
  templateUrl: './swap-item.component.html',
  styleUrls: ['./swap-item.component.scss'],
})
export class SwapItemComponent extends BaseComponent  implements OnInit {

  @Input("coinId") coinId:string = "gpt";
  @Input("title") title:string = "SWAP";
  @Input("coinsIdsFrom") coinsIdsFrom:string[] = ["GPT","USDT","KAS","KGPM","BGPM","BTC"];
  @Input("coinsIdsTo") coinsIdsTo:string[] = ["GPT","USDT","KAS","KGPM","BGPM","BTC"];

  @Input("swapReverse") swapReverse:boolean = true;

  @ViewChild("fromvalue",{static:true}) fromValueElement!:ElementRef;


 coinsFrom:Coin[] = [];
 coinsTo:Coin[] = [];
  
  availableAmountError:boolean = false;
  inputIsOnFocus:boolean = false;
  t:any;

  gptMax : number = 0;
  usdtMax : number = 0;

  get maxAvailable():number{
    //let importo = parseFloat((this.selectedCoinFrom!.maxAvailable - ((this.selectedCoinFrom!.maxAvailable*this.selectedCoinFrom!.swapFeePercent)/100)).toFixed(4) )
    if(this.selectedCoinFrom == null)
      return 0;
    let importo = this.selectedCoinFrom.maxAvailable;
    return importo;
  }
  get feeAmount():number{

    let adjustedValue = this.amountFrom - ((this.amountFrom*this.selectedCoinFrom!.swapFeePercent)/(100))
    //conversione al 106%
    return (this.amountFrom - adjustedValue)
  }
  _selectedCoinFrom: any|null;
  get selectedCoinFrom(): Coin|null {
    return this._selectedCoinFrom;
  }
  set selectedCoinFrom(value:Coin|null){
    if(value == this._selectedCoinTo && this._selectedCoinFrom!=null)
      return;
    this._selectedCoinFrom = value;
  }

  _selectedCoinTo: any|null;
  get selectedCoinTo(): Coin|null {
    return this._selectedCoinTo;
  }
  set selectedCoinTo(value:Coin|null){
    if(value == this._selectedCoinFrom && this._selectedCoinTo!=null)
      return;
    this._selectedCoinTo = value;
  }

  _amountFrom: number = 0;
  set amountFrom (value: number){
    this._amountFrom = value;
    let t = setTimeout(()=>{
      this.onValueChange();
      clearTimeout(t);
    },80);
  } 
  get amountFrom():number{
    //return parseFloat(this._amountFrom.toFixed(this.selectedCoinFrom?.decimals));
    return this.round(this._amountFrom,this.selectedCoinFrom?.decimals as number);
  }
  _amountTo: number = 0;
  set amountTo (value: number){  
    this._amountTo = value;
    let t = setTimeout(()=>{
      //this.onValueChange();
      clearTimeout(t);
    },80);
  
  } 
  get amountTo():number{
    
    //return parseFloat((this._amountTo).toFixed(this.selectedCoinTo?.decimals));
    return this.round(this._amountTo, this.selectedCoinTo?.decimals as number);
  }

  get amountSwap():number{
    if(this.amountTo == undefined)
      return 0;
    let value = this.selectedCoinFrom?.conversionFunction(this.amountFrom - this.feeAmount);
    return value ;
  }

  @ViewChild('inputSelectCoin') inputSelectCoin: IonSelect|undefined;
  toValuesFiltred: any[]=[];

  constructor(private modalController: ModalController, 
              private _userService:UsersService,
              public _dataSessionService:DatasessionService,
              private walletservice:WalletService,
              private gptService:GPTService,              
              private commonServices:CommonService,
              private _authService:AuthService) {
                super(_authService,_dataSessionService,_userService);
              }

  override async ngOnInit() {  
    this.id = this.id==""?"swapModal":this.id;
    console.log(this.id);
    this.coinsFrom = this.dataSession.getCoinsByIds(this.coinsIdsFrom);
    this.coinsTo = this.dataSession.getCoinsByIds(this.coinsIdsTo);
    await super.ngOnInit();
    let user = this._authService.loggedUser$.getValue();
    let wUser = this.walletservice.getWalletUser(user!.id!)
    this.gptMax = user!.wGPT as number;
    this.usdtMax = user!.wUSDT as number;

    this._dataSessionService.coinValues.findIndex(cv=>{ return cv.value.toLocaleLowerCase() == this.coinId.toLocaleLowerCase()});
    let coin = this._dataSessionService.getCoinsByIds([this.coinId])[0];

    this.selectedCoinFrom = coin==null?this.coinsFrom[0]:coin;//this.coinsFrom[0];
    this.selectedCoinTo = this.selectedCoinFrom.id == this.coinsTo[0].id ?this.coinsTo[1]:this.coinsTo[0];
  
  } 

  /*onCoinChange(){
    //this.toValuesFiltred=this.toValues.filter(item => item.child === this.selectedFrom.value);
    //console.log(this.toValuesFiltred, this.selectedFrom.value)

  }*/

  triggerSelectCoin($event: any) {
    console.log(this.inputSelectCoin);
    // TODO - Commentato perchè aprendo la select programmaticamente viene mostrata l'interfaccia errata
    // this.inputSelectCoin!.open();
  }

  cancel() {
    this.modalController.dismiss(null, 'cancel');
  }

  confirm() {
    let model = new SwapInput();
    model.currencyFrom = this.selectedCoinFrom?.value.toUpperCase() as string;
    model.currencyTo = this.selectedCoinTo?.value.toUpperCase() as string;
    //model.amount = this.amountFrom.toFixed(4);
    model.amount = this.round(this.amountFrom,4).toFixed(4);
    model.id = this._dataSessionService.loggedUser?.id as string;
    this.walletservice.swap(model).then((r)=>{
      if(r.success)
        this.commonServices.showToast("swapconfirm","Operation succeed!",4000,"bottom","success")      
      else
        this.commonServices.showToast("swaprerror","Something bad appened! "+r.message,4000,"bottom","danger");
      this._dataSessionService.init();
        this.modalController.dismiss(null, 'confirm');

    }).catch((error)=>{
      this.commonServices.showToast("swaperror","Something bad appened! "+error,4000,"bottom","danger");
    });
  }
  swap(){
    this.amountFrom = 0;
    this.amountTo = 0;
    let temp = this._selectedCoinFrom;
    this._selectedCoinFrom = this._selectedCoinTo;
    this._selectedCoinTo = temp;

  }
  onValueChange() {
    if(!this.inputIsOnFocus){
      this.availableAmountError = false;
      //let v = parseFloat(this.fromValueElement.nativeElement.value).toFixed(this.selectedCoinFrom?.decimals);
      let v = this.round(parseFloat(this.fromValueElement.nativeElement.value), this.selectedCoinFrom?.decimals as number).toFixed(this.selectedCoinFrom?.decimals);
      this.fromValueElement.nativeElement.value = v.toString();
      //aggiungo le fee
      let val = parseFloat(v) 
      //if(val>parseFloat(this.selectedCoinFrom!.maxAvailable.toFixed(4)) )
      if(val>this.round(this.selectedCoinFrom!.maxAvailable as number, 4))
        {
          this.commonServices.showToast("AvailableAmountError","Insufficient available balance! Please enter again.",4000,"bottom","danger");
          this.availableAmountError = true;
        }
      this.amountTo = this.selectedCoinFrom?.conversionFunction(this.amountFrom);
    }
    
  }

  max(){
    this.amountFrom = this.maxAvailable;
  }
}
