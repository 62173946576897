<ion-app class="application-wrapper" >
 
  <ion-split-pane contentId="main-content">

    <ion-menu type="overlay" content-id="main-content">
     
      <ion-content class="ion-padding" [class.withBg]="withMenuBackground" >
        <ion-list id="main-menu-list">
          <ion-list-header>
            <ion-item class="menu-item">
              <ion-img src="assets/images/Logo-Wireframe.png" class="logo"></ion-img>
            </ion-item>
          </ion-list-header>
          <div *ngFor="let item of menuItems">
            <span *ngIf="item.enabled">
              <!-- Item senza children -->
              <ion-menu-toggle auto-hide="false" *ngIf="!item.hasChildren; else withChild">
                <ion-item  routerDirection="root" (click)="item?.click(item.routerLink)" lines="none" detail="false" routerLinkActive="selected" class="menu-item no-background">
                  <ng-container *ngIf="item.css === undefined; else blockWithImage">
                    <ion-icon [name]="item.icon" slot="start"></ion-icon>
                    <ion-label>{{ item.label }}</ion-label>
                  </ng-container>
      
                  <ng-template #blockWithImage>
                    <button (click)="goItem(item)" [ngClass]="item.css" (click)="item.subMenuOpened = !item.subMenuOpened"></button>
                  </ng-template>
                </ion-item>
              </ion-menu-toggle>

              <!-- Item con children -->
              <ng-template #withChild>
                <ion-item  routerDirection="root" (click)="item?.click(item.routerLink)" lines="none" detail="false" routerLinkActive="selected" class="menu-item no-background">
                  <ng-container *ngIf="item.css === undefined; else blockWithImage">
                    <ion-icon [name]="item.icon" slot="start"></ion-icon>
                    <ion-label>{{ item.label }}</ion-label>
                  </ng-container>
      
                  <ng-template #blockWithImage>
                    <button (click)="goItem(item)" [ngClass]="item.css" (click)="item.subMenuOpened = !item.subMenuOpened"></button>
                  </ng-template>
                </ion-item>
                  <div class="sub-menu-wrapper" [ngClass]="{ opened: item.subMenuOpened }">
                    <ion-menu-toggle auto-hide="false" *ngFor="let itemChild of item.items">
                      <ion-item  routerDirection="root" (click)="itemChild?.click(itemChild.routerLink)" lines="none" detail="false" routerLinkActive="selected" class="menu-item-sub no-background">
                      <ion-label class="labelsubmenu">{{ itemChild.label }}</ion-label>
                      </ion-item>
                    </ion-menu-toggle>
                  </div>              
              </ng-template>
            </span>
          </div>
          
          
        </ion-list>          

      </ion-content>
    </ion-menu>
    <div id="main-content" style="width:100%!important; height: 78vh;">
      <app-header-main *ngIf = "isNotLogin"></app-header-main>
      <ion-content style="--offset-top: 0px; --offset-bottom: 0px;height: 83vh;" >
        
        <ion-router-outlet (activate)="onActivate($event)">

        </ion-router-outlet>

      </ion-content>
      <!--<ion-footer >
        <ion-toolbar style="--background: none;"> 
          <ion-title style="font-size:10px;
                            background: none;
                            text-align:end; 
                            font-family:'Bahnschrift'">Powered by Luxsoft be smart</ion-title>
        </ion-toolbar>
      </ion-footer>-->
    </div>

  </ion-split-pane>
  <div style="position: absolute; bottom:0px;right:10px;color:white;font-size: 9px;font-family: 'Bahnschrift';">
    V.{{version}}
  </div>
</ion-app>
